define(['app'], function(app) {

  const tabbedWidgetSet = () => {

    const component = {};

    const _config = {
      tabLinks: '[data-js-element=tab-link]',
      tabPanels: '[data-js-element=tab-panel]',
      tabLinkActive: 'tabbedWidgetSet_tabTitle-active',
      tabPanelActive: 'tabbedWidgetSet_tabPanel-active'
    };

    const _init = (element) => {
      component.element = element;
      component.bind();
    };

    const _bind = () => {
      let tabLinks = component.element.querySelectorAll(component.config.tabLinks);
      component.attachEvent(tabLinks);
    };

    const _showActiveTab = (i) => {
      const selectedTabLink = component.element.querySelectorAll(component.config.tabLinks)[i];
      const selectedTabPanel = component.element.querySelectorAll(component.config.tabPanels)[i];
      app.element.addClass(component.config.tabLinkActive, selectedTabLink);
      app.element.setAttribute('aria-selected', 'true', selectedTabLink);
      app.element.addClass(component.config.tabPanelActive, selectedTabPanel);
    };

    const _attachEvent = (tabLinks) => {
      for(let i = 0; i <tabLinks.length; i++) {
        tabLinks[i].addEventListener('click', () => { component.tabLinkClicked(i)});
      }
    };

    const _tabLinkClicked = (i) => {
      component.removeActiveClass();
      component.showActiveTab(i);
    };

    const _removeActiveClass = () => {
      let tabLinks = component.element.querySelectorAll(component.config.tabLinks);
      let tabPanels = component.element.querySelectorAll(component.config.tabPanels);
      [...tabLinks].forEach((element) => {
        app.element.removeClass(component.config.tabLinkActive, element);
        app.element.setAttribute('aria-selected', 'false', element);
      });
      [...tabPanels].forEach((element) => {
        app.element.removeClass(component.config.tabPanelActive, element);
      });
    };

    component.config = _config;
    component.init = _init;
    component.bind = _bind;
    component.showActiveTab = _showActiveTab;
    component.tabLinkClicked = _tabLinkClicked;
    component.removeActiveClass = _removeActiveClass;
    component.attachEvent = _attachEvent;
    return component;
  };

  return tabbedWidgetSet;
});
